@use "../abstracts";
.aboutMe {
  width: 100%;
  height: 100%;
  background: var(--aboutMe-bg-color);
  @include abstracts.screen(sm) {
    background: var(--aboutMe-bg-color--mobile);
  }
  &__inner {
    display: flex;

    justify-content: center;
    padding: 140px 0 140px;
    gap: 40px;
    @include abstracts.screen(sm) {
      padding: 50px 0 50px;
      flex-direction: column;
      gap: 80px;
    }
  }
}
.tag-info {
  width: 375px;
  height: 514px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f4ece6;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // offsetX, offsetY, blur-radius, color
  &__avatar {
    margin-top: 42px;
    width: 200px;
    height: 200px;
    object-fit: cover;
    // object-position: right;
    border-radius: 100%;
  }
  &__name {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.5;
    padding: 40px 0 30px;
    color: var(--primary-text-color);
  }
  &__describe {
    font-size: 2rem;
    letter-spacing: 7px;
    padding: 30px 0 50px;
    font-weight: 500;
  }
  &__separate {
    display: block;
    height: 2px;
    width: 50px;
    background-color: var(--second-color-blue);
  }
  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: var(--social-bg-color);
  }
}
.content {
  margin-top: 70px;
  width: 368px;
  &__title {
    font-size: 7rem;
    font-weight: 700;
  }
  &__describe {
    padding: 45px 0 45px;
    font-weight: 600;
    font-size: 2rem;
  }
  &__action {
    display: flex;
    gap: 10px;
    padding-bottom: 40px;
  }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 35px;
    border: 1px solid black;
    border-radius: 20px;
    font-weight: 600;
    &:hover {
      color: var(--second-text-color);
      background-color: var(--second-color-blue);
      border: 1px solid transparent;
    }
  }

  &__info-all {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__info {
    line-height: 1.7;
  }
  &__info--link {
    color: var(--second-color-blue);
    text-wrap: nowrap;
  }
}
