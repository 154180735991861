@use "../abstracts";
.admin {
  background-color: var(--primary-bg-color);
  padding: 0 0 50px;
  &__header {
    &--inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 125px;
    }
    &--left {
      display: flex;
      align-items: center;
    }
    &--left-name {
      font-size: 2rem;
      @include abstracts.screen(sm) {
        max-width: 210px;
      }
    }
    &--left-name-hl {
      font-weight: bold;
      font-size: 2.5rem;
      @include abstracts.screen(sm) {
        font-size: 2rem;
      }
    }
    &--btn {
      border-radius: 10px;
      font-size: 2rem;
      border: 2px solid;
      height: 50px;
      width: 150px;
      &:hover {
        border: 1px solid transparent;
        color: var(--second-text-color);
        background-color: var(--second-color-blue);
      }
      @include abstracts.screen(sm) {
        height: 35px;
        width: 100px;
        font-size: 1.5rem;
      }
    }
  }
  &__top {
    padding: 50px 0 0;
    &--heading {
      font-size: 4rem;
      font-weight: 900;
      text-align: center;
      @include abstracts.screen(sm) {
        font-size: 3rem;
      }
    }
  }
  &__nav {
    padding: 30px 0 0;
    display: flex;
    justify-content: center;
    &--list {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      @include abstracts.screen(sm) {
        padding: 5px 0 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
      }
    }
    &--item {
      display: block;
      cursor: pointer;
      position: relative;
      font-size: 2rem;
      font-weight: 500;
      @include abstracts.screen(sm) {
        font-size: 1.7rem;
        text-align: center;
      }
    }
  }
  &__inner {
    padding: 50px 50px;
    display: flex;
    gap: 50px;
    flex-direction: column;
    @include abstracts.screen(sm) {
      padding: 0;
    }
  }
  &__tag {
    width: 100%;
    padding: 60px 0;
    background-color: var(--form-bg-color);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    &--heading {
      margin-top: 20px;
      padding-left: 10px;
      border-left: 10px solid var(--second-color-blue);
      font-weight: 700;
      font-size: 3rem;
      line-height: 2;
      color: var(--second-color-blue);

      @include abstracts.screen(sm) {
        font-size: 2.5rem;
      }
    }
    &--top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &--btn-top {
      margin-right: 30px;
      padding: 10px;
      width: 200px;
      height: 40px;
      font-size: 1.5rem;
      font-weight: 700;
      border: 2px solid black;
      &:hover {
        border: 1px solid transparent;
        color: var(--second-text-color);
        background-color: var(--second-color-blue);
      }
      @include abstracts.screen(sm) {
        height: 60px;
        width: 130px;
        font-size: 1.5rem;
      }
    }
    &--description {
      position: relative;
      padding: 20px 50px;
      margin: 50px 50px 70px;
      border: 2px solid black;
      @include abstracts.screen(sm) {
        margin: 70px 10px 70px;
        padding: 30px 50px;
      }
    }
    &--description-project {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      @include abstracts.screen(md) {
        flex-direction: column;
      }
    }
    &--description-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      @include abstracts.screen(md) {
        flex-direction: column;
      }
    }
    &--descriptionAvatar {
      width: 300px;
      height: 300px;
      @include abstracts.screen(sm) {
        width: auto;
        height: auto;
      }
    }
    &--left {
      width: 65%;
      @include abstracts.screen(md) {
        width: 100%;
      }
    }
    &--right {
      width: 35%;
      @include abstracts.screen(md) {
        width: 100%;
      }
    }
    &--title {
      position: absolute;
      top: -40px;
      left: 20px;
      padding: 5px 10px;
      background-color: var(--form-bg-color);
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 2;
      @include abstracts.screen(sm) {
        font-size: 2rem;
      }
    }
    &--desc-Fix {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    &--desc {
      font-size: 2rem;
      line-height: 2;
      font-weight: 500;
      > strong {
        margin-left: 30px;
        font-weight: 700;
        font-size: 2rem;
        @include abstracts.screen(sm) {
          margin: 0;
        }
      }
      > stronger {
        margin-left: -10px;
        font-weight: 700;
        font-size: 2rem;
      }
      > small {
        font-size: 1.7rem;
      }
    }
    &--desc-textarea {
      font-size: 2rem;
      line-height: 2;
      font-weight: 500;
      width: 100%;
      height: 300px;
    }
    &--upImg {
      margin-top: 10px;
      gap: 15px !important;
      @include abstracts.screen(sm) {
        margin-top: 0;
      }
    }

    &--action {
      float: right;
      display: flex;
      gap: 30px;
      background-color: var(--form-bg-color);
      padding: 0 10px;
      @include abstracts.screen(sm) {
        padding: 5px 5px 10px;
      }
      &-btn {
        cursor: pointer;
        font-size: 2rem;
        font-weight: 500;
        line-height: 2;
        text-decoration: underline;
        text-underline-offset: 0.2em;
        color: var(--second-color-blue);
      }
      &-btnSkill {
        padding: 20px 0 10px;
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2;
        text-decoration: underline;
        text-underline-offset: 0.2em;
        color: var(--second-color-blue);
      }
      &-btnAvatar {
        font-size: 1.6rem;
        @include abstracts.screen(sm) {
          font-size: 2rem;
        }
      }
    }
    &--input {
      padding: 3px;
      width: 100%;
      height: 100%;
      font-size: 2rem;
      line-height: 2;
      font-weight: 500;
    }
    &--avatar {
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 100%;
    }
    &--avatarInput {
      margin-top: 10px;
    }
    &--link {
      font-size: 2rem;
      font-weight: 500;
      color: var(--second-color-blue);
      text-decoration: underline;
      text-underline-offset: 0.2em;
    }
    &--img {
      padding: 10px 0;
      width: 100%;
      height: 400px;
      object-fit: cover;
      object-position: top;
      @include abstracts.screen(md) {
        width: 100%;
      }
    }
    &--columns {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(calc(33.333% - 10px), 1fr)
      );
      gap: 10px;
      @include abstracts.screen(sm) {
        grid-template-columns: 1fr;
        text-align: center;
        gap: 50px;
      }
    }
  }
}
.rounded {
  position: absolute;
  min-width: 25px;
  min-height: 25px;
  border: 2px solid red;
  border-radius: 100%;
  right: -20px;
  top: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--second-text-color);
  background: red;
}
.fixed {
  text-align: center;
  border-radius: 30px;
  border-left: 2px solid red;
  border-right: 2px solid red;
}
.visibly {
  display: none;
}
