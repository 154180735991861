// this is responsive on my web
$grid-breakpoints: (
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin screen($key) {
  $breakpoint: map-get($grid-breakpoints, $key);
  @if ($breakpoint) {
    @media (max-width: ($breakpoint - 0.02)) {
      @content;
    }
  } @else {
    @error 'The value "#{$key}" is not in breakpoints list.';
  }
}
