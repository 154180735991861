.social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  &__icon {
    width: 20px;
    height: 20px;
  }
}
