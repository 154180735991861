@use "../abstracts";

body {
  position: relative;
}

.header {
  background-color: var(--header-bg-color);

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 125px;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__left-dot {
    margin-right: 10px;
    display: block;
    height: 10px;
    width: 10px;
    background-color: #0050ff;
  }

  &__left-name {
    font-size: 2rem;
    @include abstracts.screen(sm) {
      max-width: 210px;
    }
  }

  &__left-name-hl {
    font-weight: bold;
    font-size: 2.5rem;
  }
}

.navbar {
  &__list {
    @include abstracts.screen(md) {
      position: fixed;
      inset: 0 0 0 0;
      z-index: 9;
      margin-left: 0;
      padding: 20px 0;
      border-radius: 20px 0px 0px 20px;
      background: #f4ece6;
      translate: 100%;
      transition: translate 0.5s;
      flex-direction: column;
      justify-content: center;
      gap: 50px;
    }
    display: flex;
    align-items: center;
    gap: 30px;
  }

  &__item {
    font-size: 2rem;
    @include abstracts.screen(md) {
      font-size: 2.5rem;
      font-weight: bold;
    }
  }

  &__item:hover {
    cursor: pointer;
    color: #0050ff;
  }

  &__icon {
    display: none;

    &-svg {
      position: sticky;
      z-index: 10;
      margin-top: 10px;
      width: 50px;
      height: 60px;
      cursor: pointer; // Thêm cursor pointer cho biểu tượng
    }

    &.active {
      #top-line {
        animation: up-rotate 0.6s ease-out both;
      }
      #bottom-line {
        animation: down-rotate 0.6s ease-out both;
      }
      #middle-line {
        animation: hide 0.6s ease-out forwards;
      }
    }

    @include abstracts.screen(md) {
      display: block;
    }
  }
}

.active {
  color: #0050ff;
  text-decoration: underline;
  text-underline-offset: 10px;
}
.visible {
  translate: 0;
}

#top-line,
#bottom-line,
#middle-line {
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes up-rotate {
  0% {
    animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
    transform: translateY(0px);
  }
  30% {
    transform-origin: center;
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(-10px);
  }
  100% {
    transform-origin: center;
    transform: translateY(-10px) rotate(45deg) scale(0.9);
  }
}

@keyframes down-rotate {
  0% {
    animation-timing-function: cubic-bezier(0.16, -0.88, 0.97, 0.53);
    transform: translateY(0px);
  }
  30% {
    transform-origin: center;
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translateY(-10px);
  }
  100% {
    transform-origin: center;
    transform: translateY(-30px) rotate(-45deg) scale(0.9);
  }
}

@keyframes hide {
  29% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
