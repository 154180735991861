@use "../abstracts";
.contact {
  background-color: var(--primary-bg-color);
  &-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
  }
  &-form {
    min-width: 360px;
    max-width: 680px;
    margin-bottom: 50px;
    background-color: var(--form-bg-color);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}
