@use "../abstracts";
.resume {
  background-color: var(--primary-bg-color);

  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__title-top {
    @include abstracts.screen(sm) {
      padding-top: 50px;
    }
  }
  &__body {
    margin-top: 50px;
    max-width: 725px;
    @include abstracts.screen(sm) {
      max-width: 370px;
      margin-top: 50px;
    }
  }
  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 155px;
      height: 42px;
      font-weight: 700;
      border-radius: 20px;
      border: 1px solid var(--second-color-blue);
      background-color: var(--second-color-blue);
      color: var(--second-text-color);
      &:hover {
        border: 1px solid var(--second-color-blue);
        background-color: transparent;
        color: var(--second-color-blue);
      }
    }
  }
  &__heading {
    font-weight: 900;
    font-size: 2.5rem;
  }
  &__list {
    display: flex;
    gap: 50px;
    flex-direction: column;
    margin: 50px 0;
  }
  &__tag {
    background-color: var(--form-bg-color);
    width: 100%;
    padding: 50px 50px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    &--year {
      color: var(--second-color-blue);
      font-size: 2rem;
      font-weight: 700;
    }
    &--inner {
      display: flex;
      gap: 50px;
      margin-top: 20px;
      @include abstracts.screen(sm) {
        flex-direction: column;
        gap: 30px;
      }
    }
    &--title {
      @include abstracts.screen(sm) {
        width: 270px;
      }
    }
    &--heading {
      width: 230px;
      font-size: 2rem;
      font-weight: 500;
      line-height: 2;
    }
    &--heading-big {
      width: 230px;
      font-size: 2rem;
      font-weight: 900;
      line-height: 2;
    }
    &--heading-Small {
      font-size: 1.4rem;
    }
    &--heading-Small,
    &--content {
      line-height: 2;
      font-weight: 400;
    }
    &--frame-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
    &--list {
      margin: 20px 0 50px;
      display: grid;
      grid-template-columns: repeat(3, max-content);
      grid-row-gap: 20px;
      grid-column-gap: 50px;
      @include abstracts.screen(sm) {
        grid-template-columns: repeat(1, max-content);
        grid-row-gap: 10px;
        grid-column-gap: 30px;
      }
    }
    &--item {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &--dot {
      margin-right: 10px;
      display: block;
      height: 10px;
      width: 10px;
      background-color: var(--second-color-blue);
    }
  }
}
