@use "../abstracts";
.sign {
  background: var(--primary-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  &__top {
    text-align: center;
  }
  &__heading {
    font-size: 7rem;
    font-weight: 900;
    line-height: 2;
  }
  &__content {
    font-size: 2.5rem;
    font-weight: 700;
  }
  &__form {
    display: flex;
    align-items: center;
    justify-content: center;
    &--container {
      min-width: 500px;
      @include abstracts.screen(sm) {
        min-width: 400px;
      }
    }
    &--label {
      padding: 5px;
      background: var(--primary-bg-color);
      top: -4px;
      left: 20px;
    }
    &--input {
      border-radius: 10px;
      height: 50px;
      border: 2px solid var(--primary-text-color);
      transition: all ease-in 0.3s;
      &:focus {
        color: var(--second-color-blue);
        border: 2px solid var(--second-color-blue);
      }
      &:focus:invalid {
        border: 2px solid red;
      }
    }
    &--btn {
      width: 100%;
      height: 50px;
      font-size: 2rem;
      color: var(--primary-text-color);
      border-radius: 10px;
      border: 2px solid var(--primary-text-color);
      background-color: transparent;
      transition: all ease-in 0.2s;
      &:hover {
        color: var(--second-text-color);
        border: 2px solid var(--second-color-blue);
        background-color: var(--second-color-blue);
      }
    }
  }
}
