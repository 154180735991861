@use "../abstracts";

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Montserrat", sans-serif;
}

body {
  font-size: 1.6rem;
  // font-family: ;
  color: var(--text-color);
  background: var(--body-bg);
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
a,
img,
button,
input,
label,
textarea,
select {
  padding: 0;
  font-family: inherit;
  color: inherit;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
}
input {
  background: transparent;
}

.icon {
  filter: var(--icon-color);
}

.container {
  @include abstracts.screen(sm) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
