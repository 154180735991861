.title-top {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  &__dot {
    margin-right: 10px;
    display: block;
    height: 20px;
    width: 20px;
    background-color: var(--second-color-blue);
  }
  &__heading {
    font-weight: 900;
    font-size: 4rem;
  }
}
