@use "../abstracts";
.error {
  background: var(--primary-bg-color);
  overflow: hidden;

  &__inner {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    gap: 100px;
    @include abstracts.screen(md) {
      justify-content: center;
    }
    @include abstracts.screen(sm) {
      justify-content: center;
    }
  }
  &__left {
    position: relative;
    width: 40vw;
    height: 100vh;
    @include abstracts.screen(md) {
      display: none;
    }
    @include abstracts.screen(sm) {
      display: none;
    }
    &--name {
      display: block;
      width: 400px;
      margin: auto;
      margin-top: 40px;
      font-size: 2.5rem;
      > strong {
        display: block;
        font-size: 4rem;
        font-weight: 700;
        line-height: 1.5;
      }
    }
    &--img {
      position: absolute;
      left: -250px;
      bottom: -250px;
      width: 800px;
      height: 800px;
    }
  }
  &__right {
    @include abstracts.screen(md) {
      width: 500px;
    }
    @include abstracts.screen(sm) {
      width: 300px;
    }
  }
  &__title {
    font-size: 7rem;
    font-weight: 900;
    line-height: 2;
    @include abstracts.screen(md) {
      color: rgb(107, 0, 0);
    }
    @include abstracts.screen(sm) {
      color: rgb(107, 0, 0);
    }
  }
  &__desc {
    font-size: 3rem;
    font-weight: 500;
    width: 30vw;
    line-height: 1.5;
    @include abstracts.screen(md) {
      width: 100%;
    }
    @include abstracts.screen(sm) {
      width: 100%;
    }
  }
  &__action {
    display: flex;
    gap: 25px;
    padding: 30px 0 30px;
  }
  &__btn {
    font-size: 1.7rem;
    height: 40px;
    width: 200px;
    border: 2px solid var(--primary-text-color);
    transition: all ease-out 0.3s;
    &:hover {
      color: var(--second-text-color);
      border: 2px solid transparent;
      background-color: var(--second-color-blue);
    }
  }
}
