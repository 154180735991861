@use "../abstracts";
.form {
  &__container {
    padding: 70px 60px;
    @include abstracts.screen(sm) {
      padding: 30px 20px;
    }
  }
  &__name {
    display: flex;
    gap: 30px;
    @include abstracts.screen(sm) {
      flex-direction: column;
      gap: 0;
    }
  }
  &__input-action {
    position: relative;
    height: 65px;
    width: 100%;
    margin-bottom: 50px;
    @include abstracts.screen(sm) {
      margin-bottom: 20px;
    }
  }
  &__label {
    font-weight: 700;
    position: absolute;
    top: -8px;
    left: 10px;
  }
  &__input {
    height: 40px;
    width: 100%;
    border-radius: 0;
    border-bottom: 2px solid var(--primary-text-color);
    padding-left: 10px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 1.6rem;
    width: 100%;
    &-textarea {
      padding: 10px 10px;
      border-bottom: 3px solid var(--primary-text-color);
    }
    &:focus {
      color: var(--second-color-blue);
      border-bottom: 2px solid var(--second-color-blue);
    }
    &:focus:invalid {
      border-bottom: 2px solid red;
    }
  }
  &__submit-btn {
    background-color: var(--second-color-blue);
    color: #fff;
    border: 1px solid transparent;
  }
}
